/**
 * Menu
 */

"use strict";
import { WINDOW, DOCUMENT, CLASS_BODY, CLASS_HIDDEN } from "@utils/constants";
import { isExists, isTabletAndMobile } from "@utils/validate";

const Menu = () => {
  const obj = {};
  const CLASS_NAV = ".nav";
  const CLASS_NAV_LINK = ".dropdown .nav-link";
  const CLASS_DROPDOWN = ".dropdown";
  const CLASS_DROPDOWN_CONTENT = ".dropdown-content";
  const IS_ACTIVE = "active";
  const STR_OPEN_MENU = "open-menu";
  const ID_TOGGLE_MENU = "#toggle-menu";

  /**
   * openMenu function
   */
  obj.openMenu = function () {
    this.removeActionWhenResize();
    $(CLASS_BODY).addClass(`${STR_OPEN_MENU} ${CLASS_HIDDEN}`);
  };
  /**
   * closeMenu function
   */
  obj.closeMenu = function () {
    $(CLASS_BODY).removeClass(`${STR_OPEN_MENU} ${CLASS_HIDDEN}`);
  };
  /**
   * handleClickMenu function
   */
  obj.handleClickMenu = function () {
    let that = this;
    isExists(ID_TOGGLE_MENU) &&
      $(ID_TOGGLE_MENU).on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(CLASS_BODY).hasClass(STR_OPEN_MENU) ? that.closeMenu() : that.openMenu();
      });

    DOCUMENT.on("click", ".overlay", function (e) {
      e.stopPropagation();
      that.closeMenu();
    });
  };
  /**
   * handleMenuDropdown
   * @return {void}
   */
  obj.handleMenuDropdown = function () {
    isExists(CLASS_NAV) &&
      $(CLASS_NAV).each(function () {
        let that = $(this),
          elClick = that.find(CLASS_NAV_LINK);

        elClick.on("click", function (e) {
          if (isTabletAndMobile()) {
            e.stopPropagation();
            e.preventDefault();
            const parent = $(this).parent();
            parent.toggleClass(IS_ACTIVE).find(CLASS_DROPDOWN_CONTENT).slideToggle(150);
            $(CLASS_NAV_LINK)
              .not($(this))
              .parent()
              .removeClass(IS_ACTIVE)
              .find(CLASS_DROPDOWN_CONTENT)
              .stop()
              .slideUp(150);
          }
        });
      });
  };
  obj.removeActionWhenResize = function () {
    $(CLASS_BODY).find(CLASS_DROPDOWN).removeClass(IS_ACTIVE);
    $(CLASS_BODY).find(CLASS_DROPDOWN_CONTENT).removeAttr("style");
  };

  obj.init = function () {
    let that = this;
    this.handleClickMenu();
    this.handleMenuDropdown();

    WINDOW.resize(function () {
      if (!isTabletAndMobile()) {
        that.removeActionWhenResize();
        that.closeMenu();
      }
    });
  };

  return obj.init();
};
export default Menu;
